import { Flight, FlightPagination } from 'app/models/flight';
import { FlightService } from 'app/services/flight.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { catchError, Observable, throwError } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class FlightResolver implements Resolve<any>
{
    /**
     * Constructor
     */
    constructor(
        private _flightsService: FlightService,
        private _router: Router
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Flight>
    {
        // console.log("Flightresolver")
        // console.log(route)
        return this._flightsService.getFlightById(route.paramMap.get('id'))
                   .pipe(
                       // Error here means the requested product is not available
                       catchError((error) => {
                           // Log the error
                           console.error(error);
                           // Throw an error
                           return throwError(error);
                       })
                   );
    }
}

@Injectable({
    providedIn: 'root'
})
export class FlightByProjectNameResolver implements Resolve<any>
{
    /**
     * Constructor
     */
    constructor(
        private _flightsService: FlightService,
        private _router: Router
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Flight>
    {
        return this._flightsService.getFlightByProjectName(route.paramMap.get('name'))
                   .pipe(
                       // Error here means the requested product is not available
                       catchError((error) => {
                           // Log the error
                           console.error(error);
                           // Throw an error
                           return throwError(error);
                       })
                   );
    }
}

@Injectable({
    providedIn: 'root'
})
export class FlightsResolver implements Resolve<any>
{
    /**
     * Constructor
     */
    constructor(private _flightService: FlightService)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<{ pagination: FlightPagination; flights: Flight[] }>
    {   
        let myParam = route.data['flightResolverdata'];
        let numberOfFlights = myParam ? 10000 : 10 
        return this._flightService.getFlights(0, numberOfFlights);
    }
}
