import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';
import { ProjectsResolver } from './resolvers/projects.resolvers';
import { FlightsResolver } from './resolvers/flights.resolvers';
import { AdminGuard } from './core/auth/guards/admin.guard';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/customers'
    {path: '', pathMatch : 'full', redirectTo: 'projects'},

    // Redirect signed-in user to the '/customers'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'projects'},

    // Auth routes for guests
    {
        path: '',
        canMatch: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule)},
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule)},
        ]
    },
    // Admin routes
    {
        path: 'users',
        canMatch: [AdminGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {path: '', loadChildren: () => import('app/modules/crud/users/users.module').then(m => m.UsersModule)},
        ]
    },
    {
        path: 'customers',
        canMatch: [AdminGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {path: '', loadChildren: () => import('app/modules/crud/customers/customers.module').then(m => m.CustomersModule)},
        ]
    },
    {
        path: 'projects',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {path: '', loadChildren: () => import('app/modules/crud/projects/projects.module').then(m => m.ProjectsModule)},
        ]
    },
    {
        path: 'projects-flights',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {path: 'flight-configuration/:name', loadChildren: () => import('app/modules/configuration/flights-project-configuration/flights-project-configuration.module').then(m => m.FlightProjectConfigurationModule)},
        ]
    },
    {
        path: 'flights',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {path: '', loadChildren: () => import('app/modules/crud/flights/flights.module').then(m => m.FlightsModule)},
        ]
    },
    {
        path: 'flights/flight-configuration',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
            flights  : FlightsResolver,
            projects  : ProjectsResolver,
        },
        children: [
            {path: ':id', loadChildren: () => import('app/modules/configuration/flights-configuration/flight-configuration.module').then(m => m.FlightConfigurationModule)}
        ]
    }

];
