/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    // {
    //     id      : 'admin',
    //     title   : 'WACO srl',
    //     subtitle: 'SubZMap',
    //     type    : 'group'

    // },
    {
        id  : 'divider-2',
        type: 'divider',
        meta    : 'WACO'
    },
    {
        id      : 'users-groups',
        title   : 'Utenti e Clienti',
        subtitle: 'Gestione profili utente',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        meta    : 'WACO',
        // hidden  : userIsAdmin(),
        children: [
            {
                id   : 'users',
                title: 'Utenti',
                type : 'basic',
                icon : 'heroicons_outline:user',
                link : '/users'
            },
            {
                id   : 'customers',
                title: 'Clienti',
                type : 'basic',
                icon : 'heroicons_outline:user-group',
                link : '/customers'
            },
        ]
    },
    {
        id  : 'divider-2',
        type: 'divider'
    },
    {
        id      : 'projects-flights',
        title   : 'Voli',
        subtitle: 'Gestione voli drone',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children: [
            {
                id   : 'projects',
                title: 'Progetti',
                type : 'basic',
                icon : 'feather:compass',
                link : '/projects',
                // meta    : 'WACO',
            },
            {
                id   : 'flights',
                title: 'Voli',
                type : 'basic',
                icon : 'feather:crosshair',
                link : '/flights'
            }
        ]
    },
    // {
    //     id      : 'configuration',
    //     title   : 'Configurazione',
    //     subtitle: 'Configurazione elaborati',
    //     type    : 'group',
    //     icon    : 'heroicons_outline:home',
    //     children: [
    //         {
    //             id   : 'flights',
    //             title: 'Voli',
    //             type : 'basic',
    //             icon : 'feather:crosshair',
    //             link : '/flights-configuration'
    //         }
    //     ]
    // }
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id   : 'customers',
        title: 'Clienti',
        type : 'basic',
        icon : 'heroicons_outline:users',
        link : '/customers'
    },
    {
        id   : 'projects',
        title: 'Progetti',
        type : 'basic',
        icon : 'feather:compass',
        link : '/projects'
    },
    {
        id   : 'flights',
        title: 'Voli',
        type : 'basic',
        icon : 'feather:crosshair',
        link : '/flights'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    },
    {
        id   : 'customers',
        title: 'Clienti',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/customers'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    },
    {
        id   : 'customers',
        title: 'Clienti',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/customers'
    }
];


