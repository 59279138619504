import { environment } from '../../environment/environment';

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, filter, map, Observable, of, switchMap, take, tap, throwError } from 'rxjs';
import { User, UserPagination } from 'app/models/user';
import { AuthService } from 'app/core/auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private baseUrl = environment.apiUrl + '/auth/users';

    private _pagination: BehaviorSubject<UserPagination | null> = new BehaviorSubject(null);
    private _user: BehaviorSubject<User | null> = new BehaviorSubject(null);
    private _users: BehaviorSubject<User[] | null> = new BehaviorSubject(null);


    constructor(private http: HttpClient) {
        // this.headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*' });
        // this.headers.set('Content-Type', 'application/json; charset=utf-8');
    }

        // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    private getHeaderWithToken(){
        var headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*' });
        headers.set('Content-Type', 'application/json; charset=utf-8');
        

        var accessToken = localStorage.getItem('accessToken') ?? ''
        if(accessToken != ''){
            headers.set('Authorization', `Bearer ${accessToken}`);
        }
        
        return headers;
    }

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: User)
    {
        console.log("&/&/&/&/&/&/&/&/&/&/&/&  SET   /&/&/&/&/&/&/&/&/&/&/&/&/&/&")
        console.log(value)
        // Store the value
        this._user.next(value);
    }

    get user$(): Observable<User>
    {
        console.log("&/&/&/&/&/&/&/&/&/&/&/&  GET   /&/&/&/&/&/&/&/&/&/&/&/&/&/&")
        return this._user.asObservable();
    }


    /**
       * Getter for pagination
       */
    get pagination$(): Observable<UserPagination> {
        return this._pagination.asObservable();
    }

    /**
     * Getter for users
     */
    get users$(): Observable<User[]> {
        return this._users.asObservable();
    }


    /**
       * Get users
       *
       *
       * @param page
       * @param size
       * @param sort
       * @param order
       * @param search
       */
    getUsers(page: number = 0, size: number = 10, sort: string = 'email', order: 'asc' | 'desc' | '' = 'asc', search: string = ''):
        Observable<{ pagination: UserPagination; users: User[] }> {
        return this.http.get<{ pagination: UserPagination; users: User[] }>(
            this.baseUrl, {
            params: {
                page: '' + page,
                size: '' + size,
                sort,
                order,
                search
            },
            headers: this.getHeaderWithToken()

        }).pipe(
            tap((response) => {
                console.log(response)
                this._pagination.next(response.pagination);
                this._users.next(response.users);
            })
        );
    }

    /**
    * Get product by id
    */
    getUserById(id: string, cached: boolean): Observable<User> {
        console.log("----- geUserByID ------")
        return this._users.pipe(
            take(1),
            map((users) => {
                const user = users.find(item => item._id === id) || null;
                if(cached){
                    this._user.next(user);
                }
                
                return user;
            }),
            switchMap((user) => {

                if (!user) {
                    return throwError('Could not found user with id of ' + id + '!');
                }

                return of(user);
            })
        );
    }

    /**
    * Create user
    */
    createUser(): Observable<User> {
        // this.http.get<User>(this.baseUrl, {headers : this.headers}).subscribe(
        //     res => console.log("MIAOOOOO")
        // )
        return this.users$.pipe(
            take(1),
            switchMap(users => this.http.post<User>(this.baseUrl, { headers: this.getHeaderWithToken() }).pipe(
                map((newUser) => {
                    this._users.next([newUser, ...users]);
                    return newUser;
                })
            ))
        );
    }



    /**
       * Update user
       *
       * @param id
       * @param user
       */
    updateUser(id: string, user: User): Observable<User> {
        console.log("Update: "+id)
        return this.users$.pipe(
            take(1),
            switchMap(users => this.http.patch<User>(
                this.baseUrl, {
                params: {
                    id,
                    user
                },
                headers: this.getHeaderWithToken()
            }).pipe(
                map((updatedUser) => {
                    const index = users.findIndex(item => item._id === id);
                    console.log("map")
                    users[index] = updatedUser;
                    this._users.next(users);
                    console.log(updatedUser)
                    console.log(this.user$)
                    return updatedUser;
                }),
                switchMap(updatedUser => this.user$.pipe(
                    take(1),
                    filter(item => item && item._id === id),
                    tap(() => {
                        console.log("take - filtrer - tap")
                        this._user.next(updatedUser);
                        return updatedUser;
                    })
                ))
            ))
        );
    }


    /**
       * Delete the product
       *
       * @param id
       */
    deleteUser(userId: string): Observable<boolean> {
        return this.users$.pipe(
            take(1),
            switchMap(users => this.http.delete(this.baseUrl, {
                params: { userId }
            }).pipe(
                map((isDeleted: boolean) => {

                    // Find the index of the deleted product
                    const index = users.findIndex(item => item._id === userId);

                    // Delete the product
                    users.splice(index, 1);

                    // Update the users
                    this._users.next(users);

                    // Return the deleted status
                    return isDeleted;
                })
            ))
        );
    }
}
